export default [
  ...(process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? [
        {
          label: '** WS TEST **',
          value: 'test_ws',
        },
      ]
    : []),
  {
    label: 'Ford Italia',
    value: 'ford',
  },
  {
    label: 'Ford Kuga Fleet',
    value: 'ford_kuga_fleet',
  },
  {
    label: 'Toyota Edimotive',
    value: 'toyota_edimotive',
  },
  {
    label: 'Toyota IOL',
    value: 'toyota_iol',
  },
  {
    label: 'Electric Days',
    value: 'electric_days',
  },
  {
    label: 'Ford - Electric Days 2023',
    value: 'ford_electric_days',
  },
  {
    label: 'Ford - Electric Days 2024',
    value: 'ford_electric_days_2024',
  },
  {
    label: 'Hyundai - Electric Days 2023',
    value: 'hyundai_electric_days',
  },
  {
    label: 'Koelliker - Electric Days 2023',
    value: 'koelliker_electric_days',
  },
  {
    label: 'Giacomel',
    value: 'giacomel',
  },
  {
    label: 'Honda',
    value: 'honda',
  },
  {
    label: 'Volkswagen',
    value: 'volkswagen',
  },
];

import { put, call, takeLatest } from 'redux-saga/effects';
import types from './actionTypes';
import * as dealersActions from './actions';
import apiCall from '../../helpers/axiosApiCallWrapper';
import toastr from 'toastr';
import { VW_BRANDS } from '../../config/settings';

function* syncDealersSaga({ params }) {
  const carBrand = params.carBrand.toLowerCase();
  try {
    if (!(carBrand && VW_BRANDS.includes(carBrand)))
      throw new Error('Invalid car brand');

    const dealers = yield call(apiCall, 'POST', `/getDealers`, {
      carBrand,
    });

    yield put(dealersActions.syncDealersSuccess(dealers));
  } catch (error) {
    yield put(dealersActions.syncDealersFailure(error));
    toastr.error('Error syncing dealers');
  }
}

function* dealerSaga() {
  yield takeLatest(types.SYNC_DEALERS.REQUEST, syncDealersSaga);
}

export default dealerSaga;

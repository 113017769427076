import { combineReducers } from 'redux';

import Layout from './layout/reducer';

import Auth from './auth/reducer';
import Dashboard from './dashboard/reducer';
import Leads from './leads/reducer';
import People from './people/reducer';
import Audience from './audiences/reducer';
import DailyLeadInsights from './leads/dailyInsights/reducer';
import DailyFBCampaignInsights from './facebookAd/dailyCampaignInsights/reducer';
import DailyGoogleCampaignInsights from './googleAd/dailyCampaignInsights/reducer';
import Customer from './customers/reducer';
import DailyCustomerInsights from './customers/dailyInsights/reducer';
import AcquisitionOrder from './acquisitionOrders/reducer';
import MonetizationOrder from './monetizationOrders/reducer';
import DailyMonetizationOrderInsights from './monetizationOrders/dailyInsights/reducer';
import MonetizationItem from './monetizationItems/reducer';
import MarketingCampaign from './marketingCampaigns/reducer';
import LeadField from './leadFields/reducer';
import LeadForm from './leadForms/reducer';
import Campaign from './campaigns/reducer';
import DailyCampaignInsights from './campaigns/dailyInsights/reducer';
import Survey from './surveys/reducer';
import SurveyForm from './surveyForms/reducer';
import MessageTemplate from './messageTemplates/reducer';
import SmsMessage from './smsMessages/reducer';
import PushNotification from './pushNotifications/reducer';
import Call from './calls/reducer';
import CallInsights from './callInsights/reducer';
import NextCall from './nextCall/reducer';
import Supplier from './suppliers/reducer';
import DailySaleInsights from './suppliers/dailySaleInsights/reducer';
import DailyPublisherInsights from './suppliers/dailyPublisherInsights/reducer';
import User from './users/reducer';
import Country from './countries/reducer';
import DailyCountryInsights from './countries/dailyInsights/reducer';
import Dealer from './dealers/reducer';

const rootReducer = combineReducers({
  Layout,
  Auth,
  Dashboard,
  Leads,
  People,
  Audience,
  DailyLeadInsights,
  DailyFBCampaignInsights,
  DailyGoogleCampaignInsights,
  Customer,
  DailyCustomerInsights,
  AcquisitionOrder,
  MonetizationOrder,
  DailyMonetizationOrderInsights,
  MonetizationItem,
  MarketingCampaign,
  LeadField,
  LeadForm,
  Campaign,
  DailyCampaignInsights,
  Survey,
  SurveyForm,
  MessageTemplate,
  SmsMessage,
  PushNotification,
  Call,
  CallInsights,
  NextCall,
  Supplier,
  DailySaleInsights,
  DailyPublisherInsights,
  User,
  Country,
  DailyCountryInsights,
  Dealer,
});

export default rootReducer;

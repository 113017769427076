import types from './actionTypes';

const initialState = {
  loading: false,
  dealers: [],
  error: '',
};

const dealerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_DEALER_STATE:
      return initialState;
    case types.SYNC_DEALERS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.SYNC_DEALERS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        dealers: [...action.dealers],
      };
    case types.SYNC_DEALERS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default dealerReducer;

const statsTypes = {
  RESET_DEALER_STATE: 'RESET_DEALER_STATE',
  SYNC_DEALERS: {
    REQUEST: 'SYNC_DEALERS.REQUEST',
    SUCCESS: 'SYNC_DEALERS.SUCCESS',
    FAILURE: 'SYNC_DEALERS.FAILURE',
  },
};

export default statsTypes;

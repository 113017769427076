import React, { useState, useMemo } from 'react';
import { APIProvider, Map as GoogleMap } from '@vis.gl/react-google-maps';
import Marker from './Marker';

const calculateCenter = (coordinates) => {
  if (!coordinates.length) return { lat: 0, lng: 0 };

  let sumLat = 0;
  let sumLng = 0;

  coordinates.forEach((coord) => {
    sumLat += coord.lat;
    sumLng += coord.lng;
  });

  const avgLat = sumLat / coordinates.length;
  const avgLng = sumLng / coordinates.length;

  return { lat: avgLat, lng: avgLng };
};

const Map = ({ markers }) => {
  const filteredMarkers = markers.filter(
    ({ lat, lng }) => !isNaN(lat) && !isNaN(lng),
  );
  const coordinates = filteredMarkers.map(({ lat, lng }) => ({
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  }));
  const center = useMemo(() => calculateCenter(coordinates), [coordinates]);

  return (
    <APIProvider
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={['marker']}>
      <GoogleMap
        mapId="dealer-map"
        language="it"
        style={{ width: '100%', height: '100%' }}
        defaultCenter={center}
        defaultZoom={7}
        gestureHandling={'greedy'}
        disableDefaultUI={false}>
        {filteredMarkers.map(({ id, lat, lng, title, description }) => (
          <Marker
            key={id}
            position={{ lat, lng }}
            title={title}
            description={description}
          />
        ))}
      </GoogleMap>
    </APIProvider>
  );
};

export default Map;

import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_DEALER_STATE,
});

export const syncDealers = (params) => ({
  type: types.SYNC_DEALERS.REQUEST,
  params,
});

export const syncDealersSuccess = (dealers) => ({
  type: types.SYNC_DEALERS.SUCCESS,
  dealers,
});

export const syncDealersFailure = (error) => ({
  type: types.SYNC_DEALERS.FAILURE,
  error,
});
